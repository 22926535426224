<script setup lang="ts">
import { refAutoReset } from '@vueuse/core'

const isBuisy = refAutoReset(false, UI_UNLOCK_TIMEOUT)
const isNavbarEnabled = ref(true)

provide('navbar-enabled', isNavbarEnabled)
provide('page-loading', isBuisy)
</script>

<template>
    <div class="layout-default">
        <!-- <BgCanvas class="layout-default__orbs" /> -->
        <TheHeader class="layout-default__header" />

        <main class="layout-default__content">
            <slot />
        </main>

        <div
            class="layout-default__loading"
            v-if="isBuisy"
        >
            <div class="layout-default__loading-backdrop" />
            <LoadingDots class="layout-default__loading-dots" />
        </div>

        <TheNavbar
            v-if="isNavbarEnabled"
            class="layout-default__navbar"
        />
    </div>
</template>

<style lang="scss">
.layout-default {
    min-height: 100dvh;
    display: grid;

    max-width: 768px;
    margin: auto;

    background-image: url('~/assets/bg/bg-blur-violett-orange.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    &__orbs {
        z-index: 0;
    }

    &__content {
        position: relative;
        padding: 24px;
        padding-top: 70px;
        padding-bottom: 130px;
        flex: 1 1 100%;
        z-index: 1;

        & > div {
            height: 100%;
            display: grid;
        }
    }

    &__navbar {
        position: fixed;
        bottom: 24px;
        left: 16px;
        right: 16px;
        z-index: 2;
    }

    &__loading {
        display: grid;
        position: relative;
        grid-row: 1 / -1;
        grid-column: 1 / -1;

        min-height: 100dvh;
        inset: 0;
        z-index: 2;

        &-backdrop {
            background: rgba(218, 237, 239, 0.53);
            height: 100%;
            min-height: 100dvh;
            grid-row: 1 / -1;
            grid-column: 1 / -1;
        }

        &-dots {
            place-self: center;
            grid-row: 1 / -1;
            grid-column: 1 / -1;
        }
    }
}
</style>
