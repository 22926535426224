<script setup lang="ts"></script>

<template>
    <div class="the-navbar">
        <NuxtLink
            class="the-navbar__item"
            :to="{ name: PAGE.PROFILE, replace: true }"
        >
            <IconButton
                icon="user-24x"
                auto-color="stroke"
                :label="$t('navbar.profile')"
            />
        </NuxtLink>

        <NuxtLink
            class="the-navbar__item"
            :to="{ name: PAGE.ACHIEVEMENTS, replace: true }"
        >
            <IconButton
                icon="medal-24x"
                auto-color="stroke"
                :label="$t('navbar.achievements')"
            />
        </NuxtLink>

        <NuxtLink
            :to="{ name: PAGE.REWARDS, replace: true }"
            class="the-navbar__item"
            custom
            #default="{ navigate }"
        >
            <IconButton
                icon="coupon-24x"
                :auto-color="['stroke', 'fill']"
                :label="$t('navbar.rewards')"
                style="opacity: 0.2; cursor: default"
            />
        </NuxtLink>
    </div>
</template>

<style lang="scss">
.the-navbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    gap: v(s-10);
    background: #1b1b1b;
    color: v(color-text-light);
    border-radius: v(s-6);
    max-width: 450px;
    margin: auto;
    padding: 0 16px;

    > * {
        flex: 1 0 33%;
    }

    &__item {
        transition: color v(transition-hover);
    }

    .router-link-exact-active {
        color: v(color-link);
    }
}
</style>
