<script setup></script>

<template>
    <div class="loader">
        <i class="dot"></i>
        <i class="dot"></i>
        <i class="dot"></i>
    </div>
</template>

<style lang="scss">
.loader {
    display: inline-flex;
    place-self: center;
    gap: 12px;
}

.dot {
    width: 12px;
    height: 12px;
    background-color: #f7f7f7;
    opacity: 0.65;
    border-radius: 50%;
    animation: dot-bounce 1200ms ease-in-out 0s infinite forwards;
}

.dot:nth-child(1) {
    animation-delay: 0ms;
}

.dot:nth-child(2) {
    animation-delay: 100ms;
}

.dot:nth-child(3) {
    animation-delay: 200ms;
}

@keyframes dot-bounce {
    50%,
    0%,
    100% {
        transform: translateY(0px);
        opacity: 0.65;
    }
    75% {
        transform: translateY(-20px);
        opacity: 1;
    }
}
</style>
