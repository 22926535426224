<script setup lang="ts">
import { type Props as IconProps } from './Icon.vue'

export type IconButtonProps = {
    icon: string
    label?: string
}

export type Props = IconButtonProps & Pick<IconProps, 'fluid' | 'autoColor'>

const props = withDefaults(defineProps<Props>(), {})

const iconProps = reactivePick(props, 'fluid', 'autoColor')
</script>

<template>
    <figure class="icon-button">
        <Icon
            v-bind="iconProps"
            :name="icon"
            class="icon-button__icon"
        />

        <figcaption
            v-if="label"
            class="icon-button__label"
        >
            {{ label }}
        </figcaption>
    </figure>
</template>

<style lang="scss">
.icon-button {
    display: flex;
    flex-flow: column;
    place-content: center;
    place-items: center;
    gap: v(s-6);
    margin: 0;
    padding: v(s-16);

    &__icon {
    }

    &__label {
        line-height: 1;
    }
}
</style>
